<template>
  <v-row>
    <v-col cols="12">
      <v-simple-table>
        <template #default>
          <thead>
            <tr>
              <th v-for="(header, i) in table.headers" :id="`header_${i}`" :key="i" :style="{ width: header.width, textAlign: header.textCenter ? 'center' : 'left' }">
                {{ header.name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(user, i) in ranks">
              <tr :key="`rank-${i}`">
                <td class="text-center">
                  {{ i + 1 }}
                </td>
                <td class="text-center">
                  <template v-if="$store.state.user.user.admin || $store.state.user.user.id === user.id">
                    {{ user.username }}
                  </template>
                  <template v-else>
                    <span v-for="j in user.id.length" :key="j">
                      *
                    </span>
                  </template>
                </td>
                <td class="text-center">
                  {{ user.activityPoints ? user.activityPoints.toLocaleString() : '-' }}
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'RankContent',
    data () {
      return {
        table: {
          headers: [
            {
              name: '순위',
              width: '20%',
              textCenter: true,
            },
            {
              name: '이름',
              width: '40%',
              textCenter: true,
            },
            {
              name: '포인트',
              width: '40%',
              textCenter: true,
            },
          ],
        },
        ranks: [],
      }
    },
    beforeMount () {
      this.getRanks()
    },
    methods: {
      getRanks () {
        this.$axios.get('user/rank')
          .then(res => {
            if (res && res.data) {
              this.ranks = res.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      },
    },
  }
</script>

<style scoped>

</style>
