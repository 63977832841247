<template>
  <v-lazy
    :options="{
      threshold: .5
    }"
  >
    <v-container>
      <rank-header />
      <rank-content />
    </v-container>
  </v-lazy>
</template>

<script>
  import RankHeader from '@/views/rank/Header'
  import RankContent from '@/views/rank/Content'
  export default {
    name: 'Rank',
    components: { RankContent, RankHeader },
  }
</script>

<style scoped>

</style>
