<template>
  <v-row>
    <v-col cols="12">
      <v-subheader class="px-0">
        Activation Rankings.
      </v-subheader>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'RankHeader',
  }
</script>

<style scoped>

</style>
